import { setGlobalTokens } from '@ehi/styles';

import './src/css/index.css';
import './src/css/template-sutter.css';

import wrapWithPageData from './wrap-with-pagedata';
import wrapWithProvider from './wrap-with-provider';

export const wrapRootElement = wrapWithProvider;
export const wrapPageElement = wrapWithPageData;

setGlobalTokens();

const addScript = () => {
    const globalScripts = document.createElement('script');
    globalScripts.innerHTML = `!function (e, v, o) { e[v] || (e[v] = [], e[v].x = e[v].push, e[v].push = function (a, b) { this.x([a, b]) }) }(window, "_waEvents");`;
    document.head.appendChild(globalScripts);
};

export const onClientEntry = () => {
    window.onload = () => {
        addScript();
        setupRedirectScript();
    };
};

function setupRedirectScript() {
    // Listen to all clicks in the capture phase
    document.addEventListener(
        'click',
        (event) => {
            const target = event.target as HTMLElement;
            const anchor = target.closest('a');
            if (!anchor) return;

            const url = new URL(anchor.href, window.location.href);
            if (url.origin !== window.location.origin) return;

            const forceReloadPatterns = [
                '/medicare/enrollment/',
                '/medicare/eligibility/',
                '/medicare/coverage/',
                '/medicare/cost/',
                '/medicare/parts/',
                '/medicare/states/',
                '/medicare/managing-medicare/',
            ];

            if (forceReloadPatterns.some((pattern) => url.pathname.startsWith(pattern))) {
                event.preventDefault();
                event.stopPropagation();
                window.location.href = anchor.href;
            }
        },
        true
    );
}
